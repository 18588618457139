import axios from "axios";
import auth from "./auth";

const get = async (uri) =>
  await axios
    .get(process.env.REACT_APP_AXIOS_URL + uri)
    .then((res) => (res.status === 204 ? [] : res.data))
    .catch((e) => {
      throw e;
    });

const post = async (uri, data) =>
  await axios
    .post(process.env.REACT_APP_AXIOS_URL + uri, data)
    .then((res) => res.data)
    .catch((e) => {
      throw e;
    });

const getT = async (uri) =>
  await axios
    .get(process.env.REACT_APP_AXIOS_URL + uri, {
      headers: { Authorization: auth().token },
    })
    .then((res) => (res.status === 204 ? undefined : res.data))
    .catch((e) => {
      throw e;
    });

const postT = async (uri, data) =>
  await axios
    .post(process.env.REACT_APP_AXIOS_URL + uri, data, {
      headers: { Authorization: auth().token },
    })
    .then((res) => res.data)
    .catch((e) => {
      throw e;
    });

export const getLocation = (e) => post("location", { address: e });
export const searchAddress = (text) => getT("address/search?text=" + text);
export const bulkResolveAddresses = (addresses) =>
  postT("address/resolve/bulk", { addresses });
export const getGoods = (e) => post("goods", { keyword: e });
export const getPrice = (origin, destin, items) =>
  postT("form/calculate-price", { ...origin, ...destin, items });
export const getAddress = (e) => postT("form/find-address", { postcode: e });
export const createPaymentIntent = (data) =>
  postT("payment/create-payment-intent", data);
export const createPaymentIntentV2 = (data) => postT("payment/intents", data);
export const payMultipleByCard = (data) =>
  postT("payment/pay-multiple-by-card", data);
export const payMultipleByInvoice = (data) =>
  postT("payment/pay-multiple-by-invoice", data);
export const getCartByUUID = (uuid) => getT(`payment/cart/${uuid}`);
export const getOriginDates = (origin, destin) =>
  getT(`routes-calendar?area_from=${origin}&area_to=${destin}`);
export const getDestinDates = (e, origin, destin) =>
  postT("routes-calendar", {
    collection_date: e,
    area_from: origin,
    area_to: destin,
  });
export const postLog = (e) => post("log/write", e);
export const getOrderList = (e) => postT("customer/orders/all", e);
export const getOrderInfo = (orderID) => postT(`customer/orders/${orderID}`);
export const postRegister = (e) => post("customer/register", e);
export const postLogin = (e) => post("customer/login", e);
export const postForgotPass = (e) => post("customer/password/forgot", e);
export const postResetPass = (e) => post("customer/password/reset", e);
export const postCustomerPassChange = (e) =>
  postT("customer/password/change", e);
export const getSetting = () => post("get-setting", { key: "search_engine" });
export const getSettings = () => get("settings");
export const postCustomerUpdateProfile = (e) =>
  postT("customer/profile/update", e);
export const getCustomerAddress = () => getT("customer/addresses");
export const postCustomerAddress = (e) =>
  postT("customer/addresses/primary", e);
export const getCustomerInformation = () => getT("customer/information");
export const postCustomerAddressFavorite = (e) =>
  postT("customer/addresses/favorite", e);

export const updateFavoriteItems = (data) =>
  postT("customer/goods", { goods: data });
export const getFavoriteItems = () => getT("customer/goods");
export const cancelOrder = (e) => postT("customer/orders/cancel", e);
