import { createContext, useContext } from "react";
import { cartStore } from "../stores/cart.store";

const CartContext = createContext();

function CartProvider({ children }) {
  return <CartContext.Provider value={cartStore}>{children}</CartContext.Provider>;
}

function useCartStore() {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCartStore must be used within a CartProvider");
  }
  return context;
}

export { CartProvider, useCartStore };
