import jwt from 'jsonwebtoken';

const auth = () => {
  const token = JSON.parse(localStorage.getItem('token'));

  return token && Date.now() < jwt.decode(token?.access_token)?.exp * 1000
    ? {
        state: true,
        token: `${token?.token_type} ${token.access_token}`,
        user: token.user,
      }
    : { state: false };
};

export default auth;
