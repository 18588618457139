import dayjs from "dayjs";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

export function initDayjs() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Etc/UTC");
}
