import { Suspense, lazy, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { usePD } from "./context/context";

import TagManager from "react-gtm-module";
import { ToastContainer } from "react-toastify";

import Preloader from "./components/Preloader";
import AuthVerify from "./utils/Authentify";

import "react-toastify/dist/ReactToastify.css";
import ".././scss/App.scss";

import ModalError from "./ModalError";
import { getSettings } from "./utils/dataApi";
import errorHandler from "./utils/errorHandler";

import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import UpButton from "./components/UpButton";
import { initDayjs } from "./utils/init-dayjs";
import InconsistenciesItems from "./components/InconsistenciesItems";

initDayjs();

const tagManagerArgs = { gtmId: "GTM-MR2PDWR" };

//Pages
const OrdersPage = lazy(() => import("./Pages/OrdersPage"));
const LoginPage = lazy(() => import("./Pages/LoginPage"));
const ForgotPassPage = lazy(() => import("./Pages/ForgotPassPage"));
const ResetPassPage = lazy(() => import("./Pages/ResetPassPage"));
const CustomerDetailsPage = lazy(() => import("./Pages/CustomerDetailsPage"));
const UserPage = lazy(() => import("./Pages/UserPage"));
const AddressPage = lazy(() => import("./Pages/AddressPage"));
const QuickOrderPage = lazy(() => import("./Pages/QuickOrder/QuickOrderPage"));
const DiscountPage = lazy(() => import("./Pages/Discount"));

const ProoviaHeader = lazy(() => import("./Sections/ProoviaHeader"));
const ProoviaFooter = lazy(() => import("./Sections/ProoviaFooter"));

const App = () => {
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = usePD();

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    if (process.env.REACT_APP_ENV === "prod")
      TagManager.initialize(tagManagerArgs);

    const token = JSON.parse(localStorage.getItem("token"));
    if (token)
      dispatch(
        { type: "SET_USER", payload: token?.user },
        dispatch({ type: "SET_TOKEN", payload: token })
      );

    getSettings()
      .then((res) => {
        dispatch({ type: "SET_SETTINGS", payload: res }, setLoading(false));
        if (
          res.version !== (localStorage.getItem("version") ?? "0.0.0") ||
          localStorage.getItem("searchEngine") !== res.search_engine
        ) {
          localStorage.removeItem("formValues");
          localStorage.setItem("version", res.version);
          localStorage.setItem("searchEngine", res.search_engine);
        }
      })
      .catch((err) => {
        errorHandler(err, dispatch);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        const loc = window.location.hash;
        document
          .getElementById(loc.substr(1))
          ?.scrollIntoView({ block: "center", behavior: "smooth" });
      }, 2000);
    }
  }, [loading]);

  return loading ? (
    <Preloader />
  ) : (
    <>
      <Router>
        <Switch>
          <Route Route path="/couriers-privacy-policy">
            <Suspense fallback={<Preloader />}>
              <PrivacyPolicy />
            </Suspense>
          </Route>

          <Route path="/login">
            {!state.user ? (
              <Suspense fallback={<Preloader />}>
                <ProoviaHeader />
                <LoginPage />
                <ProoviaFooter />
              </Suspense>
            ) : (
              <Redirect to="/user/orders" />
            )}
          </Route>

          <Route path="/passwordrecovery">
            <Suspense fallback={<Preloader />}>
              <ProoviaHeader />
              <ForgotPassPage />
              <ProoviaFooter />
            </Suspense>
          </Route>

          <Route path="/reset-password/:token" exact>
            <Suspense fallback={<Preloader />}>
              <ProoviaHeader />
              <ResetPassPage />
              <ProoviaFooter />
            </Suspense>
          </Route>

          <Route path="/user" exact>
            {state.user ? (
              <Suspense fallback={<Preloader />}>
                <ProoviaHeader />
                <UserPage />
                <ProoviaFooter />
              </Suspense>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/user/orders" exact>
            {state.user ? (
              <Suspense fallback={<Preloader />}>
                <ProoviaHeader />
                <OrdersPage />
                <ProoviaFooter />
              </Suspense>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/user/addresses" exact>
            {state.user ? (
              <Suspense fallback={<Preloader />}>
                <ProoviaHeader />
                <AddressPage />
                <ProoviaFooter />
              </Suspense>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/user/details" exact>
            {state.user ? (
              <Suspense fallback={<Preloader />}>
                <ProoviaHeader />
                <CustomerDetailsPage />
                <ProoviaFooter />
              </Suspense>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/user/discount" exact>
            {state.user ? (
              <Suspense fallback={<Preloader />}>
                <ProoviaHeader />
                <DiscountPage />
                <ProoviaFooter />
              </Suspense>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/user/quickorder">
            {state.user ? (
              <Suspense fallback={<Preloader />}>
                <ScrollToTop />
                <ProoviaHeader />
                <QuickOrderPage />
                <ProoviaFooter />
              </Suspense>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/" exact>
            {state.user ? (
              <Suspense fallback={<Preloader />}>
                <ProoviaHeader />
                <UserPage />
                <ProoviaFooter />
              </Suspense>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
        </Switch>
      </Router>
      <ToastContainer hideProgressBar={true} />
      <AuthVerify />
      {state.user && <InconsistenciesItems />}
      <ModalError {...state.modalError} />
      <UpButton />
    </>
  );
};

export default App;
