import { toast } from "react-toastify"
import { rsp } from "./utils"

const errorHandler = (err, dispatch) => {

    if (err?.response?.status === 401) {
        dispatch({
            type: 'SET_MODAL_ERROR', payload: {
                show: true,
                footerContent:
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> <button className='btn btn-danger' onClick={() => {
                        localStorage.removeItem('token')
                        localStorage.removeItem('formValues')
                        window.location.reload()
                    }}>OK</button></div>,
                bodyContent: <div style={{ textAlign: 'center' }}><h5>Your session has expired, please relogin</h5></div>,
            }
        })
    }
    else toast.error(rsp(err))
}


export default errorHandler