const Preloader = () =>
    <div className="preloader">
        <div className="preloader-body">
            <div className="cssload-container" style={{ height: 100 }}>
                <img alt='Proovia' src='/images/logo.svg' width={250}></img>
            </div>
            <p>Loading...</p>
        </div>
    </div>



export default Preloader