import { useEffect, useState } from "react";

const UpButton = () => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        window.onscroll = () => {
            if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
                setShow(true)
            } else {
                setShow(false)
            }
        };
    }, [])


    return (
        <span id='ui-to-top'
            className={'ui-to-top fa fa-angle-up' + (show ? ' active' : '')}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        />
    )
}

export default UpButton