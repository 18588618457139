import { StrictMode } from "react";
import { render } from "react-dom";
import App from "./js/App";
import Helmet from "react-helmet";
import { ProoviaDelivery_Provider } from "./js/context/context";
import { CartProvider } from "./js/context/cart.context";

render(
  <StrictMode>
    <Helmet>
      {/* generate error Using UNSAFE_componentWillMount */}
      {process.env.REACT_APP_ENV === "prod" && (
        <meta name="facebook-domain-verification" content="je80xyu0p9u7et20z93gxjncsapvzs" />
      )}
    </Helmet>
    <ProoviaDelivery_Provider>
      <CartProvider>
        <App />
      </CartProvider>
    </ProoviaDelivery_Provider>
  </StrictMode>,
  document.getElementById("root")
);
