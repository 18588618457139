import errorHandler from "./errorHandler";

export const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


export const addDays = (date, days) => {
    let d = new Date(date)
    d.setDate(d.getDate() + days);
    return d;
}

export const checkNumber = (num) => !num.length || num.match(/^[0-9]+$/) ? true : false

export const checkPhone = (num) => !num.length || num.match(/^[0-9 ()+-]+$/) ? true : false

export const rsp = (err) => err.response?.data?.message || err.message

export const formatTime = (d) => {
    const date = new Date(d)
    return date.toLocaleTimeString("en-GB")
}

export const formDate = (d) => {
    const date = new Date(d)
    return date.toLocaleDateString("en-GB")
}

export const truncateToDecimals = (num, fixed = 2) => {
    let reg = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(reg)[0];
}

export const openAttachment = (url) => {
    try{
        window.open(url);
    } catch(error) {
        errorHandler('Sorry, something went wrong!');
    }
}