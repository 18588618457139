import {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";

const LOCAL_STORAGE_KEY = 'pd_client_notify';
const LOCAL_STORAGE_VERSION = 1;

export default function InconsistenciesItems() {
  const [showModal, setShowModal] = useState(false);
  const storageKey = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))

  useEffect(() => {
    if (!storageKey || storageKey?.v !== LOCAL_STORAGE_VERSION) {
      setShowModal(true)
    }
  }, []);

  const onSubmit = () => {
    setShowModal(false)
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
      v: LOCAL_STORAGE_VERSION,
      value: true
    }))
  }

  return (
    <Modal show={showModal} centered>
      <Modal.Header>
        <h5>Dear Customer</h5>
      </Modal.Header>
      <Modal.Body>
        <p>We have noticed some inconsistencies in item selections which have impacted our delivery efficiency. To
          ensure a smoother delivery process, please ensure that you select items accurately as per the delivery
          guidelines.</p>
        <p>Thank you for your cooperation.</p>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-danger' onClick={onSubmit}>OK</button>
      </Modal.Footer>
    </Modal>
  )
}
