import { createContext, useReducer, useContext } from 'react'
import { Logger } from '../utils/stateLogger'

const ProoviaDelivery_StateContext = createContext()
const ProoviaDelivery_DispatchContext = createContext()

const ProoviaDelivery_Reducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action);
}
const handlers = {
    'SET_TOKEN': (state, { payload }) => ({ ...state, token: payload }),
    'SET_USER': (state, { payload }) => ({ ...state, user: payload }),
    'SET_SETTINGS': (state, { payload }) => ({ ...state, settings: payload }),
    'SET_MODAL_ERROR': (state, { payload }) => ({ ...state, modalError: payload }),
    // 'RESET': () => ({}),
    DEFAULT: (state, action) => ({ ...state, ...action })
}

const ProoviaDelivery_Provider = ({ children }) => {
    const initialState = {
        logined: false,
        showSider: true,
        overSider: false,
        token: null,
        user: undefined,
        settings: {},
        rights: [],
        modalError: {},
    }
    const [state, dispatch] = useReducer(Logger(ProoviaDelivery_Reducer), initialState)

    return (
        <ProoviaDelivery_StateContext.Provider value={state}>
            <ProoviaDelivery_DispatchContext.Provider value={dispatch}>
                {children}
            </ProoviaDelivery_DispatchContext.Provider>
        </ProoviaDelivery_StateContext.Provider>
    )
}

const usePDState = () => {

    const context = useContext(ProoviaDelivery_StateContext)
    if (context === undefined) {
        throw new Error('usePAState must be used within a ProoviaDelivery_Reducer')
    }
    return context
}

const usePDDispatch = () => {
    const context = useContext(ProoviaDelivery_DispatchContext)
    if (context === undefined) {
        throw new Error('usePADispatch must be used within a ProoviaDelivery_Provider')
    }
    return context
}

const usePD = () => [usePDState(), usePDDispatch()]

export { ProoviaDelivery_Provider, usePDState, usePDDispatch, usePD }