import { useEffect } from "react"
import { usePDDispatch } from "../context/context"

const AuthVerify = () => {

    const dispatch = usePDDispatch()

    useEffect(() => {
        window.addEventListener('storage', () => {
            if (!JSON.parse(localStorage.getItem('token'))) {
                localStorage.removeItem('token')
                dispatch({ type: 'SET_USER', payload: undefined })
                dispatch({ type: 'SET_TOKEN', payload: undefined })
            }
        });
        return window.removeEventListener('storage', {})
    }, [])
    return <></>
}


export default AuthVerify